import React, { useEffect } from 'react'

import classNames from 'classnames'
import './styles.scss'

interface IFullScreenLoaderProps {
  hideContent?: boolean
  disableScroll?: boolean
}

export const FullScreenLoader: React.FC<IFullScreenLoaderProps> = ({
  disableScroll,
  hideContent = false,
}) => {
  useEffect(() => {
    if (disableScroll) return
    document.body.classList.add('disable-scroll')
    return () => {
      document.body.classList.remove('disable-scroll')
    }
  }, [disableScroll])

  return (
    <div
      className={classNames('loader-box', {
        hideContent,
      })}
      ref={r =>
        r?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    >
      <div className="loader"></div>
    </div>
  )
}
