import { Metadata } from 'next'
import { camelCase, chunk, join, reverse } from 'lodash'
import { sendGTMEvent } from '@next/third-parties/google'
import { notFound } from 'next/navigation'
import axios, { AxiosError } from 'axios'
import { capitalize } from 'lodash'
import deepEqual from 'deep-equal'

import {
  IFee,
  TLang,
  TExtras,
  TCoverage,
  TCurrency,
  IPageMeta,
  TEquipment,
  TExtraAction,
  IPricedEquip,
  ICalculation,
  IVehicleCharge,
  IPricedCoverage,
  ICurrencyResponse,
} from '@/types'
import {
  API_ENDPOINT,
  APP_LANGUAGES,
  APP_NAME,
  CURRENCY_KEY,
} from '@/constants'
import endpoints from '@/apis/endpoints'
import { IStorageData } from '@/context'
import { DepthKeys } from '@/hooks'

import { getBlogPostsPage } from './request'
import { getStorageValue } from './storage'
import { appPaths, homePath } from './urls'

export const getMessages = async (appLang: TLang) => {
  if (!APP_LANGUAGES.includes(appLang)) return notFound()
  try {
    const response = await fetch(
      `http://localhost:${process.env.PORT}/api/${appLang}`,
      {
        next: {
          revalidate: 0,
        },
        //@ts-ignore
        headers: {
          'X-Api-Key': process.env.TRIGGER_X_API_KEY,
        },
      },
    )
    const data = await response.json()
    return JSON.parse(data ?? '{}')
  } catch (error) {
    return notFound()
  }
}

export const generatePageMetaData = (
  data: Partial<Metadata>,
): Partial<Metadata> => {
  return {
    ...data,
    title: `${data.title || ''} | Enterprise`,
  }
}

export const getImage = (path?: string, isBackgroundImage = true) => {
  const imgPath = `${API_ENDPOINT}/storage/${path}`
  if (!path) return ''
  if (!isBackgroundImage) return imgPath
  return `url("${imgPath}")`
}

export const generateFormData = <T>(data: T) => {
  const formData = new FormData()

  for (const key in data) {
    // @ts-ignore
    formData.append(key, data[key])
  }
  return formData
}

export const joinArray = <T = Maybe<string>>(
  joinWith = ',',
  ...elements: T[]
): string => elements.filter(el => !!el).join(joinWith)

const stripUnit = (value: number): number => {
  if (typeof value === 'number' && !isNaN(value) && !Number.isNaN(value)) {
    return value / (value * 0 + 1)
  }
  return value
}

/**
 * Convert pixel to rem
 * @param value pixel value
 * @param fontSize base font size
 * @returns rem value
 * @example
 *  const fontSize = rem(32, 16); // => fontSize = '2rem';
 */

export const rem = (value: number | string, fontSize = 16): string => {
  if (isNaN(+value)) return value.toString()

  return `${stripUnit(+value) / stripUnit(fontSize)}rem`
}

export function getUniqueValues<T>(array: T[], property: keyof T) {
  return [...new Set(array.map(item => item[property]))]
}

export const extractErrorFromResponse = (error: AxiosError<any>) => {
  const message = error.response?.data?.message || error.message

  if (error.response?.status === 401) {
    // @ts-ignore
    window?.signOut?.()
  }

  return message
}

export const extractNumberParts = (inputString: string) => {
  // Parse the input string to a floating-point number
  const numberValue = parseFloat(inputString)

  // Check if the parsing was successful
  if (!isNaN(numberValue)) {
    // Extract integer and decimal parts
    const integerPart = Math.floor(numberValue)
    const decimalPart = numberValue - integerPart

    return [integerPart, decimalPart]
  } else {
    return [0, 0]
  }
}

export const ellipsizeMiddle = (
  inputString: string,
  maxLength = 14,
  skip = false,
) => {
  if (inputString.length <= maxLength || skip) {
    return inputString
  }

  const halfLength = Math.floor((maxLength - 3) / 2)
  const leftHalf = inputString.slice(0, halfLength)
  const rightHalf = inputString.slice(inputString.length - halfLength)

  return leftHalf + '...' + rightHalf
}

export function shallowEqual<T>(actual: T, expected: T): boolean {
  return deepEqual(actual, expected)
}

export const filterFees = (fee: IFee) => {
  // eslint-disable-next-line eqeqeq
  return fee.purpose == '7' && fee.includedInRate == 'false'
}

function mergeArray(
  oldArray: (TCoverage | TEquipment)[],
  newArray: (TCoverage | TEquipment)[],
) {
  const mergedArray: (TCoverage | TEquipment)[] = []
  oldArray = oldArray.map(_v => ({ ..._v, action: 'Cancel' }))
  oldArray.forEach(oldItem => {
    // @ts-ignore
    const newItemIndex = newArray.findIndex(item => item.type === oldItem.type)

    if (newItemIndex !== -1) {
      const newItem = newArray[newItemIndex]
      const mergedItem = {
        ...oldItem,
        ...newItem,
        action: newItem.action || ('Replace' as TExtraAction),
      }

      mergedArray.push(mergedItem)

      // Remove merged item from the new array
      newArray.splice(newItemIndex, 1)
    } else {
      // Item only exists in the old array
      mergedArray.push(oldItem)
    }
  })

  // Add items that only exist in the new array
  mergedArray.push(...newArray)

  return mergedArray
}

export const replaceExtras = (
  current?: TExtras,
  newExtras?: TExtras,
): TExtras | undefined => {
  const _newExtras = (newExtras ?? {}) as TExtras
  // @ts-ignore
  _newExtras['coverage'] = mergeArray(
    current?.coverage || [],
    // @ts-ignore
    newExtras?.coverage || [],
  )

  // @ts-ignore
  _newExtras['equipments'] = mergeArray(
    current?.equipments || [],
    // @ts-ignore
    newExtras?.equipments || [],
  )

  return _newExtras
}

export const extractExtraName = (
  extra: IPricedEquip | IPricedCoverage,
  t: (_key: DepthKeys, _options?: any) => string,
  isRussian: boolean,
  calc?: ICalculation,
) => {
  const currency = getStorageValue<TCurrency>(CURRENCY_KEY)
  const isRentalPeriod = calc?.unitName === 'RentalPeriod'
  const description = isRussian
    ? // @ts-ignore
      extra?.equipment?.equipType || extra?.code
    : // @ts-ignore
      extra?.equipment?.description || extra?.details
  return joinArray(
    ' @ ',
    `${capitalize(
      // @ts-ignore
      isRussian ? t(`${description}Title`) : description,
    )} ${isRentalPeriod ? '/' : calc?.quantity} ${
      !isRentalPeriod
        ? // @ts-ignore
          t(`${calc?.unitName}s`)
        : // @ts-ignore
          t(calc?.unitName)
    }`,
    !isRentalPeriod
      ? t('extrasPrice', {
          // @ts-ignore
          period: t(calc?.unitName),
          total: calc?.unitCharge ?? extra?.charge?.amount,
          currency: extra.charge.currencyCode || currency?.name,
        })
      : undefined,
  )
}

export const extractVehicleDetails = (
  charge: IVehicleCharge,
  t: (_key: DepthKeys, _options?: any) => string,
) => {
  const isRentalPeriod = charge.calculation.unitName === 'RentalPeriod'
  return joinArray(
    ' @ ',
    joinArray(
      ' ',
      // @ts-ignore
      t(camelCase(charge.description)),
      t('perPeriod', {
        count: isRentalPeriod ? '/' : charge.calculation.quantity,
        period: joinArray(
          '',
          !isRentalPeriod
            ? // @ts-ignore
              t(`${charge.calculation.unitName}s`)
            : // @ts-ignore
              t(charge.calculation.unitName),
        ),
      }),
    ),
    !isRentalPeriod
      ? t('extrasPrice', {
          currency: charge.currencyCode,
          // @ts-ignore
          period: t(charge.calculation.unitName),
          total: charge.calculation.unitCharge,
        })
      : undefined,
  )
}

export const capitalizeWordInText = (
  text: string,
  targetWord1: string,
  targetWord2: string,
) => {
  // Create a regular expression with the target word and the 'g' flag for global search
  const regex = new RegExp(`\\b(${targetWord1}|${targetWord2})\\b`, 'gi')

  // Use the replace function to capitalize the target word in the text
  const capitalizedText = text.replace(regex, match => capitalize(match))

  return capitalizedText
}

export const humanFormatNumber = (
  numberStr: number | string,
  delimiter = ' ',
) => {
  // if (isNaN(numberStr)) return numberStr
  const intPart = `${numberStr}`.split('.')[0]
  const decimalPart = `${numberStr}`.split('.')[1]
  const splittedChars = intPart.split('')
  const symbol = ['-', '+'].includes(splittedChars?.[0])
    ? splittedChars?.[0]
    : ''
  const _intPart = symbol ? splittedChars.slice(1) : splittedChars
  const newIntPart = join(
    reverse(chunk(reverse(_intPart), 3).map(s => join(reverse(s), ''))),
    delimiter,
  )

  const newNumberStr = isNaN(+decimalPart)
    ? newIntPart
    : `${newIntPart}.${decimalPart}`

  return `${symbol}${newNumberStr}`
}

export const getCurrencyValue = async () => {
  const response = await axios.get(
    `${API_ENDPOINT}/api${endpoints.exchangeRate}`,
  )

  const { data } = response.data as ICurrencyResponse
  return data
}

export const cleanDecimalPartForLanguage = (numStr: string, lang?: string) => {
  const clean = ['AMD'].includes(lang ?? '')
  if (!clean) return numStr
  return numStr.replace(/\.\d*(?=\s)/, '')
}

export const replacePhoneNumber = (number?: string) => {
  let _number = number
  if (!_number) return _number
  _number = _number.replace('00', '+')

  if (!_number.includes('+')) return `+${_number}`
  return _number
}

export const generateMetaObject = (
  meta: IPageMeta,
  lang: TLang,
): Partial<Metadata> => {
  return {
    title: meta?.meta_title || APP_NAME,
    description: meta?.meta_description ?? 'Description',
    alternates: {
      canonical: joinArray(
        '',
        process.env.NEXT_PUBLIC_CLIENT_PATH,
        lang === 'ru' ? `/${lang}` : undefined,
        meta.path === homePath ? undefined : meta.path,
      ),
      languages: [...APP_LANGUAGES, 'x-default'].reduce(
        (prev, lang) => ({
          ...prev,
          [lang]: joinArray(
            '',
            `${process.env.NEXT_PUBLIC_CLIENT_PATH}`,
            joinArray(
              '',
              ['en', 'x-default'].includes(lang) ? undefined : '/',
              ['en', 'x-default'].includes(lang) ? undefined : lang,
            ),
            meta.path === homePath ? undefined : meta.path,
          ),
        }),
        {},
      ),
    },
    verification: {
      google: process.env.GOOGLE_SITE_VERIFICATION,
    },
    openGraph: {
      type: 'website',
      title: meta?.meta_title || APP_NAME,
      siteName: meta?.meta_title || APP_NAME,
      description: meta?.meta_description ?? 'Description',
      images: meta?.meta_image
        ? [getImage(meta?.meta_image, false)]
        : undefined,
    },
    twitter: {
      card: 'summary_large_image',
      title: meta?.meta_title || APP_NAME,
      description: meta?.meta_description ?? 'Description',
      images: meta?.meta_image
        ? [getImage(meta?.meta_image, false)]
        : undefined,
    },
    keywords: meta.meta_keywords,
    publisher: process.env.PUBLISHER,
  }
}

export const registerEvent = (
  event: 'purchase' | 'refund',
  data?: IStorageData,
) => {
  if (!data) return
  const { customer, totalCharge, fees, pricedEquips, pricedCoverages, confId } =
    data.reservation
  sendGTMEvent({
    event,
    ecommerce: {
      pickupLocation: data.location_info.pickUpLocation,
      user: {
        email: data.email,
        name: `${customer.givenName ?? ''} ${customer.surname ?? ''}`,
      },
      transaction_id: confId.id,
      currency: totalCharge.currencyCode,
      value: +totalCharge.estimatedTotalAmount.replaceAll(/[,]/g, ''),
      tax: fees
        .filter(filterFees)
        .reduce((acc, cur) => acc + +cur.amount.replaceAll(/[,]/g, ''), 0),
      items: [
        ...pricedEquips
          .filter(_v => parseInt(_v.charge.amount) !== 0)
          .map(_v => ({
            item_id: _v.equipment.equipType,
            item_name: _v.equipment.description,
            price: +_v.charge.amount.replaceAll(/[,]/g, ''),
            quantity: +_v.equipment.quantity.replaceAll(/[,]/g, ''),
          })),
        ...pricedCoverages
          .filter(_v => parseInt(_v.calculation.total) !== 0)
          .map(_v => ({
            item_name: _v.details,
            item_id: _v.coverageType,
            quantity: +_v.calculation.quantity.replaceAll(/[,]/g, ''),
            price: +_v.calculation.unitCharge.replaceAll(/[,]/g, ''),
          })),
      ],
    },
  })
}

export const buildLanguageBasedSitemap = async (lang: TLang) => {
  const blogs = (await getBlogPostsPage(lang)).data.items.map(
    _blog => _blog.slug,
  )

  const paths = [
    ...appPaths[lang].map((path, idx) => ({
      lastModified: new Date().toISOString(),
      priority: !idx ? 1 : 0.8,
      changeFrequency: !idx ? 'yearly' : 'monthly',
      url: joinArray(
        '',
        process.env.NEXT_PUBLIC_CLIENT_PATH,
        lang === 'en' ? undefined : '/',
        lang === 'en' ? undefined : lang,
        lang === 'ru' && path === homePath ? undefined : path,
      ),
    })),
    ...blogs.map(_v => ({
      lastModified: new Date().toISOString(),
      changeFrequency: 'monthly',
      priority: 0.8,
      url: joinArray(
        '',
        process.env.NEXT_PUBLIC_CLIENT_PATH,
        lang === 'en' ? undefined : '/',
        lang === 'en' ? undefined : lang,
        '/',
        _v,
      ),
    })),
  ]

  return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${paths
        .map(
          path => `
        <url>
          <loc>${path.url}</loc>
          <lastmod>${path.lastModified}</lastmod>
          <changefreq>${path.changeFrequency}</changefreq>
          <priority>${path.priority}</priority>
        </url>`,
        )
        .join('')}
    </urlset>`
}
