import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { useRouter } from 'next-intl/client'
import moment from 'moment'
import _ from 'lodash'

import {
  homePath,
  extrasPath,
  reviewPath,
  vehiclePath,
  replaceExtras,
  getStorageValue,
  setStorageValue,
  removeStorageValue,
  reservationSuccessPath,
  locationPath,
} from '@/utils'
import {
  useReserveNow,
  useSelectVehicle,
  useStartReservation,
  useModifyReservation,
} from '@/apis'
import {
  TIMEOUT,
  START_KEY,
  CONFIRMED_KEY,
  SHOW_TIMEOUT_POPUP,
  TIMEOUT_CHECK_INTERVAL,
} from '@/constants'
import {
  IFee,
  TFunc,
  IBranch,
  TExtras,
  ICoupon,
  IVehicle,
  IResponse,
  IReference,
  IVehicleInfo,
  IPricedEquip,
  ITotalCharge,
  IRateDistance,
  IVehicleCharge,
  IAdditionalInfo,
  IPricedCoverage,
  IArrivalDetails,
  IAdditionalSchema,
  IReserveNowPayload,
  IReserveNowResponse,
  TStartReservationForm,
  IStartReservationPayload,
  IStartReservationResponse,
  TLang,
} from '@/types'
import { useOnResize } from '@/hooks'
import { useParams } from 'next/navigation'

export type TStartReservationStorageData =
  IResponse<IStartReservationResponse> & {
    fees?: IFee[]
    startedAt: Date
    vehicle?: IVehicle
    isModifying?: boolean
    reference?: IReference
    isLocationPage: boolean
    selectedExtras?: TExtras
    totalCharge?: ITotalCharge
    extras?: IPricedCoverage[]
    equipments?: IPricedEquip[]
    rateDistance?: IRateDistance
    vehicleCharges?: IVehicleCharge[]
    parameters: TStartReservationForm & {
      email?: string
      lastName?: string
      firstName?: string
      phoneNumber?: string
      reservationNumber?: string
    }
    arrivalDetails: IArrivalDetails
    unmodifiedData?: IReserveNowResponse
  } & IAdditionalSchema

type TReservationDetails = Omit<
  Partial<IReserveNowPayload>,
  | keyof TStartReservationForm
  | 'pickUpTime'
  | 'returnTime'
  | 'vehicle'
  | 'reference'
  | 'equipments'
> & {
  fees?: IFee[]
  vehicle?: IVehicle
  reference?: IReference
  totalCharge?: ITotalCharge
  rateDistance?: IRateDistance
  vehicleCharges?: IVehicleCharge[]
}

interface IReservationContext {
  onModify: TFunc
  coupon?: ICoupon
  isMobile: boolean
  onContinue: TFunc
  isLoading: boolean
  vehicle?: IVehicle
  currentStep: number
  locations: IBranch[]
  locationStep: number
  showCountDown: boolean
  onCloseCountDown: TFunc
  isDateEditing?: boolean
  extras: IPricedCoverage[]
  vehicles?: IVehicleInfo[]
  terms?: IAdditionalInfo[]
  equipments: IPricedEquip[]
  isLocationEditing?: boolean
  onExitWithoutModifying: TFunc
  onReserveNow: (
    _payload: Pick<
      IReserveNowPayload,
      'email' | 'phoneNumber' | 'firstName' | 'lastName'
    >,
  ) => void
  parameters?: TStartReservationForm
  reservationDetails?: TReservationDetails
  onSelectExtras: (_payload: TExtras) => void
  onSelectVehicle: (
    _payload: Pick<IVehicleInfo, 'reference' | 'vehicle'>,
  ) => void
  toStep: (_step: number, _skip?: boolean) => void
  onUpdate: (
    _payload: Partial<IStartReservationPayload>,
    _preventNext?: boolean,
  ) => void
  onUpdateFlight: (_payload: Partial<IArrivalDetails>) => void
}

const paths: Record<number, string> = {
  2: locationPath,
  3: vehiclePath,
  4: extrasPath,
  5: reviewPath,
}

export const ReservationContext = createContext({} as IReservationContext)

export const ReservationContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const router = useRouter()
  const params = useParams()
  const isTimerClosed = useRef(false)
  const [currentStep, _setStep] = useState(2)
  const [isMobile, setIsMobile] = useState(false)
  const [locationStep, setServerStep] = useState(1)
  const [vehicle, setVehicle] = useState<IVehicle>()
  const [isDateEditing, setDateEditing] = useState(false)
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const [locations, setLocations] = useState<IBranch[]>([])
  const [showCountDown, setShowCountDown] = useState(false)
  const [extras, setExtras] = useState<IPricedCoverage[]>([])
  const [equipments, setEquipments] = useState<IPricedEquip[]>([])
  const [isLocationEditing, setLocationEditing] = useState(false)
  const [unmodifiedData, setUnmodifiedData] = useState<IReserveNowResponse>()
  const [storageData, setData] = useState<TStartReservationStorageData>()
  const [tempExtras, setTempExtras] = useState<TExtras>()
  const [reservationDetails, setReservationDetails] =
    useState<TReservationDetails>({
      fees: undefined,
      vehicle: undefined,
      reference: undefined,
      totalCharge: undefined,
      rateDistance: undefined,
      vehicleCharges: undefined,
    })

  useEffect(() => {
    const lang = params.lang
    const pickupLocation = storageData?.parameters.pickUpLocation
    const dropOffLocation = storageData?.parameters.dropOffLocation
    if (!pickupLocation) return
    const isEvn = [
      pickupLocation.toLowerCase(),
      dropOffLocation?.toLowerCase(),
    ].some(_v => _v?.includes('yerevan'))
    const _opts = isEvn !== undefined && !isEvn ? { locale: 'en' as TLang } : {}
    if (Object.keys(_opts).length && lang !== 'en') {
      router.replace(vehiclePath, _opts)
    }
  }, [
    params,
    router,
    storageData?.parameters.pickUpLocation,
    storageData?.parameters.dropOffLocation,
  ])

  const { mutateAsync: selectVehicle, isPending: isVehicleLoading } =
    useSelectVehicle()
  const { mutateAsync: reserveNow, isPending: isReservationLoading } =
    useReserveNow()

  const { mutateAsync: reservationStart, isPending: isStartingReservation } =
    useStartReservation()

  const {
    mutateAsync: modifyReservation,
    isPending: isReservationModifyLoading,
  } = useModifyReservation()

  const onResize = useCallback(() => {
    setIsMobile(window?.innerWidth < 1124)
  }, [])

  useOnResize(onResize)

  const setStep = useCallback(
    (pageNumber: number) => {
      const _path = paths[pageNumber]
      if (_path) {
        router.replace(_path)
      }
      _setStep(pageNumber)
    },
    [router],
  )

  const onContinue = useCallback(() => {
    const data = getStorageValue<TStartReservationStorageData>(START_KEY)
    setStorageValue(START_KEY, {
      ...data,
      startedAt: new Date(),
    })
    setShowCountDown(false)
  }, [])

  // checking timeout
  useEffect(() => {
    const interval = setInterval(() => {
      const data = getStorageValue<TStartReservationStorageData>(START_KEY)
      const diff = moment().diff(data?.startedAt, 'millisecond')
      if (diff >= SHOW_TIMEOUT_POPUP && !isTimerClosed.current) {
        setShowCountDown(true)
      }
      if (diff >= TIMEOUT) {
        removeStorageValue(START_KEY)
        router.replace(homePath)
      }
    }, TIMEOUT_CHECK_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [router])

  // redirect home when reservation steps opened manually
  useEffect(() => {
    const parameters = getStorageValue(START_KEY)
    if (!parameters) {
      router.replace(homePath)
    }
  }, [router])

  useEffect(() => {
    clearTimeout(timerRef.current)
    setIsMobile(window?.innerWidth < 1124)
    const data = getStorageValue<TStartReservationStorageData>(START_KEY)
    if (!data) {
      return window?.location?.replace?.(homePath)
    }
    if (data && !data.isLocationPage) {
      setData(data)
    } else if (data) {
      setData({
        // @ts-ignore
        parameters: {
          pickUpTime: data.parameters.pickUpTime,
          returnTime: data.parameters.returnTime,
          pickUpDate: data.parameters.pickUpDate,
          returnDate: data.parameters.returnDate,
        },
      })
      // @ts-ignore
      setLocations(data.data as IBranch[])
      setServerStep(data.step)
    }
    if (!data?.isLocationPage) {
      setStep(3)
    }
    if (data?.isModifying) {
      setVehicle(data.vehicle)
      setUnmodifiedData(data.unmodifiedData)
      setEquipments(data.equipments || [])
      setExtras(data.extras || [])
      setReservationDetails(prev => ({
        ...prev,
        fees: data?.fees,
        vehicle: data?.vehicle,
        reference: data?.reference,
        extras: data?.selectedExtras,
        totalCharge: data?.totalCharge,
        rateDistance: data?.rateDistance,
        vehicleCharges: data?.vehicleCharges,
        arrivalDetails: data?.arrivalDetails,
        ...data.parameters,
      }))
      const timer = setTimeout(() => {
        // @ts-ignore
        window?.onUpdateTotal?.(data.totalCharge.estimatedTotalAmount)
        clearTimeout(timer)
      }, 400)
      setStep(5)
    }
    return () => {
      timerRef.current = setTimeout(() => {
        removeStorageValue(START_KEY)
        clearTimeout(timerRef.current)
      }, 300)
    }
  }, [setStep])

  const toStep = useCallback(
    (step: number, skip?: boolean) => {
      const _skip =
        skip || ([3, 4, 5].includes(step) && reservationDetails.vehicle)
      if (step >= currentStep && !_skip) return
      switch (step) {
        case 1:
          if (isLocationEditing) {
            setLocationEditing(false)
          }
          setDateEditing(prev => !prev)
          break
        case 2:
          if (isDateEditing) {
            setDateEditing(false)
          }
          setLocationEditing(prev => !prev)
          break
        case 3:
          // @ts-ignore
          // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          setReservationDetails(
            ({
              // extras,
              ...rest
            }) => ({
              ...rest,
            }),
          )
          setStep(step)
          break
        case 4:
          // @ts-ignore
          // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          setReservationDetails(({ ...rest }) => ({
            ...rest,
            // extras: {
            //   equipments: [],
            //   coverage: [],
            // },
          }))
          setStep(step)
          break
        case 5:
          setStep(step)
          break

        default:
          break
      }
    },
    [
      reservationDetails.vehicle,
      setStep,
      currentStep,
      isLocationEditing,
      isDateEditing,
    ],
  )

  const onSelectVehicle = useCallback(
    async (
      { vehicle, reference }: Pick<IVehicleInfo, 'reference' | 'vehicle'>,
      extras?: TExtras,
    ) => {
      const {
        driverAge,
        pickUpDate,
        returnDate,
        differentLocation,
        reservationNumber,
        ...rest
      } = storageData?.parameters || {}
      const { vehicleType, vehicleMakeModel } = vehicle
      const size_int = vehicleType.size
      const doorCount = vehicleType.doorCount
      const airConditioning = vehicle.airConditioning
      const category_int = vehicleType.vehicleCategory
      const transmissionType = vehicle.transmissionType
      const _payload = {
        [extras ? 'extras' : '']: extras,
        vehicle: {
          // fuel_type: '',
          // drive_type: '',
          transmissionType,
          code: vehicleMakeModel.code,
          sizeInt: size_int ? +size_int : undefined,
          airConditioning: airConditioning === 'true',
          doorCount: doorCount ? +doorCount : undefined,
          categoryInt: category_int ? +category_int : undefined,
        },
      }
      const response = await selectVehicle({
        ..._payload,
        ...rest,
        driverAge: `${driverAge}`,
        // @ts-ignore
        differentLocation: `${differentLocation}`,
        returnDate: moment(returnDate).format('YYYY-MM-DD'),
        pickUpDate: moment(pickUpDate).format('YYYY-MM-DD'),
      })
      const data = response.data.data.vehicles
      if (!extras) {
        setEquipments(data.pricedEquips)
        setExtras(data.pricedCoverages)
      }
      setReservationDetails(prev => ({
        ...(prev || {}),
        reference,
        fees: data.fees,
        vehicle: { ...data.vehicle },
        totalCharge: { ...data.totalCharge },
        rateDistance: data.rentalRate.rateDistance,
        vehicleCharges: data.rentalRate.vehicleCharges,
        extras: reservationNumber
          ? replaceExtras(prev.extras ?? tempExtras, extras)
          : extras,
      }))
      setVehicle(vehicle)
      // @ts-ignore
      window.onUpdateTotal(data.totalCharge.estimatedTotalAmount ?? '')
      _setStep(prev => {
        const _step = ++prev
        setStep(_step)
        return _step
      })
      window?.scrollTo?.({
        top: 0,
        behavior: 'smooth',
      })
    },
    [selectVehicle, setStep, storageData?.parameters, tempExtras],
  )

  const onSelectExtras = useCallback(
    (extras: TExtras) => {
      const { reference } = reservationDetails || {}
      if (!vehicle || !reference) return
      setReservationDetails(prev => ({
        ...(prev || {}),
        extras,
      }))
      onSelectVehicle({ vehicle, reference }, extras)
    },
    [reservationDetails, vehicle, onSelectVehicle],
  )

  const onReserveNow = useCallback(
    async (
      payload: Pick<
        IReserveNowPayload,
        'email' | 'phoneNumber' | 'firstName' | 'lastName'
      >,
    ) => {
      const {
        extras: selectedExtras,
        vehicle,
        reference,
        arrivalDetails,
      } = reservationDetails
      if (!selectedExtras || !vehicle || !reference) return
      const {
        driverAge,
        pickUpDate,
        returnDate,
        pickUpLocation,
        dropOffLocation,
        differentLocation,
        ...rest
      } = storageData?.parameters || {}
      // Vehicle destructuring
      const {
        vehicleType,
        airConditioning,
        transmissionType,
        vehicleMakeModel,
      } = vehicle
      const size_int = vehicleType.size
      const doorCount = vehicleType.doorCount
      const category_int = vehicleType.vehicleCategory
      const _payload: IReserveNowPayload = {
        ...rest,
        ...payload,
        arrivalDetails: arrivalDetails ?? {},
        vehicle: {
          transmissionType,
          code: vehicleMakeModel.code,
          sizeInt: size_int ? +size_int : undefined,
          airConditioning: airConditioning === 'true',
          doorCount: doorCount ? +doorCount : undefined,
          categoryInt: category_int ? +category_int : undefined,
        },
        reference,
        extras: selectedExtras,
        driverAge: `${driverAge}`,
        // @ts-ignore
        pickUpLocation,
        dropOffLocation,
        // @ts-ignore
        differentLocation: `${differentLocation}`,
        returnDate: moment(returnDate).format('YYYY-MM-DD'),
        pickUpDate: moment(pickUpDate).format('YYYY-MM-DD'),
      }
      const response = await reserveNow(_payload)
      setStorageValue(CONFIRMED_KEY, response.data.data)
      const timer = setTimeout(() => {
        router.replace(reservationSuccessPath)
        clearTimeout(timer)
      }, 200)
    },
    [router, reserveNow, reservationDetails, storageData?.parameters],
  )

  const isLoading = useMemo(
    () =>
      isVehicleLoading ||
      isReservationLoading ||
      isStartingReservation ||
      isReservationModifyLoading,
    [
      isVehicleLoading,
      isReservationLoading,
      isStartingReservation,
      isReservationModifyLoading,
    ],
  )

  const cleanup = useCallback(() => {
    // @ts-ignore
    window.onUpdateTotal('0.00')
    setVehicle(undefined)
    if (reservationDetails?.reservationNumber) {
      setTempExtras(reservationDetails?.extras)
    }
    setReservationDetails(({ ...rest }) => ({
      ...rest,
      fees: undefined,
      extras: undefined,
      vehicle: undefined,
      reference: undefined,
      totalCharge: undefined,
      rateDistance: undefined,
      vehicleCharges: undefined,
    }))
  }, [reservationDetails.extras, reservationDetails.reservationNumber])

  const onUpdate = useCallback(
    async (
      payload: Partial<IStartReservationPayload>,
      preventNext?: boolean,
    ) => {
      const data = getStorageValue<TStartReservationStorageData>(START_KEY)
      if (!data?.parameters) return

      const { driverAge, pickUpDate, returnDate, differentLocation, ...rest } =
        data.parameters

      const _payload: IStartReservationPayload = {
        ...rest,
        driverAge: `${driverAge}`,
        // @ts-ignore
        differentLocation: `${differentLocation}`,
        returnDate: moment(returnDate).format('YYYY-MM-DD'),
        pickUpDate: moment(pickUpDate).format('YYYY-MM-DD'),
        ...payload,
      }
      const response = await reservationStart(_payload)
      const filter = response.data.step === 2 ? ['dropOffLocation'] : []
      const _storageData = _.omit(data.parameters, filter)
      const _data = {
        ...response.data,
        startedAt: new Date(),
        parameters: {
          ..._storageData,
          ...payload,
        },
      }
      setStorageValue(START_KEY, _data)
      setData(_data as TStartReservationStorageData)
      setDateEditing(false)
      setLocationEditing(false)
      cleanup()
      if (response?.data?.isLocationPage) {
        setStep(2)
        setServerStep(response.data.step)
        setLocations(response.data.data as IBranch[])
      } else {
        setLocations([])
        if (preventNext) {
          setStep(3)
          return
        }
        _setStep(prev => {
          const _step = ++prev
          setStep(_step)
          return _step
        })
      }
    },
    [reservationStart, cleanup, setStep],
  )

  const onModify = useCallback(async () => {
    const {
      vehicle,
      reference,
      arrivalDetails,
      extras: selectedExtras,
    } = reservationDetails
    if (!selectedExtras || !vehicle || !reference) return
    const { vehicleType, airConditioning, transmissionType, vehicleMakeModel } =
      vehicle
    const size_int = vehicleType.size
    const doorCount = vehicleType.doorCount
    const category_int = vehicleType.vehicleCategory
    const data = getStorageValue<TStartReservationStorageData>(START_KEY)
    if (!data?.parameters) return

    const {
      driverAge,
      pickUpDate,
      returnDate,
      pickUpLocation,
      dropOffLocation,
      differentLocation,
      ...rest
    } = data.parameters

    const _payload: IReserveNowPayload = {
      ..._.omit(reservationDetails, [
        'fees',
        'totalCharge',
        'vehicleCharges',
        'rateDistance',
      ]),
      vehicle: {
        transmissionType,
        code: vehicleMakeModel.code,
        sizeInt: size_int ? +size_int : undefined,
        airConditioning: airConditioning === 'true',
        doorCount: doorCount ? +doorCount : undefined,
        categoryInt: category_int ? +category_int : undefined,
      },
      ...rest,
      reference,
      extras: selectedExtras,
      driverAge: `${driverAge}`,
      arrivalDetails: arrivalDetails ?? {},
      // @ts-ignore
      pickUpLocation,
      dropOffLocation,
      // @ts-ignore
      differentLocation: `${differentLocation}`,
      returnDate: moment(returnDate).format('YYYY-MM-DD'),
      pickUpDate: moment(pickUpDate).format('YYYY-MM-DD'),
    }
    const response = await modifyReservation(_payload)
    _payload.extras = {
      coverage: _payload.extras.coverage.filter(_v => _v.action !== 'Cancel'),
      equipments: _payload.extras.equipments.filter(
        _v => _v.action !== 'Cancel',
      ),
    }
    setStorageValue(CONFIRMED_KEY, response.data.data)
    const timer = setTimeout(() => {
      router.replace(reservationSuccessPath)
      clearTimeout(timer)
    }, 200)
  }, [router, modifyReservation, reservationDetails])

  const onCloseCountDown = useCallback(() => {
    setShowCountDown(false)
    isTimerClosed.current = true
  }, [])

  const onExitWithoutModifying = useCallback(() => {
    if (!unmodifiedData) return
    setStorageValue(CONFIRMED_KEY, {
      ...unmodifiedData,
    })
    const timer = setTimeout(() => {
      router.replace(reservationSuccessPath)
      clearTimeout(timer)
    }, 200)
  }, [router, unmodifiedData])

  const onUpdateFlight = useCallback((payload: Partial<IArrivalDetails>) => {
    setReservationDetails(prev => ({
      ...(prev || {}),
      arrivalDetails: {
        ...(prev?.arrivalDetails || {}),
        ...payload,
      },
    }))
  }, [])

  const value = useMemo(
    () => ({
      extras,
      toStep,
      vehicle,
      isMobile,
      onUpdate,
      onModify,
      locations,
      isLoading,
      equipments,
      onContinue,
      currentStep,
      locationStep,
      onReserveNow,
      isDateEditing,
      showCountDown,
      onSelectExtras,
      onUpdateFlight,
      onSelectVehicle,
      onCloseCountDown,
      isLocationEditing,
      reservationDetails,
      onExitWithoutModifying,
      coupon: storageData?.data?.coupon,
      terms: storageData?.data?.info || [],
      parameters: storageData?.parameters,
      vehicles: storageData?.data?.vehicles || [],
    }),
    [
      extras,
      toStep,
      vehicle,
      isMobile,
      onModify,
      onUpdate,
      locations,
      isLoading,
      equipments,
      onContinue,
      currentStep,
      locationStep,
      onReserveNow,
      showCountDown,
      isDateEditing,
      onSelectExtras,
      onUpdateFlight,
      onSelectVehicle,
      onCloseCountDown,
      isLocationEditing,
      reservationDetails,
      onExitWithoutModifying,
      storageData?.data?.info,
      storageData?.parameters,
      storageData?.data?.coupon,
      storageData?.data?.vehicles,
    ],
  )
  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  )
}
